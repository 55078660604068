import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Team from "../components/team"
import Colors from "../variables/colors"

const About = styled.section`
  max-width: 800px;
  margin: 30px auto;
  padding: 0 20px;
`

const Text = styled.p`
  font-size: 1.1em;
  line-height: 1.4em;
`

const Heading = styled.h2`
  margin-top: 3em;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Ably Labs - meet the team" />
    <About>
      <h1>Welcome!</h1>
      <Text>We're the Ably DevRel team. We make demos and tutorials to help you get to grips with building realtime applications using Ably's infrastructre.</Text>
      <Text>Whether it is SDKs, reliable data streaming or code samples, we want you to have a pleasant experience developing with Ably! If we can help you to do so please don't hesitate to contact us on devrel@ably.io</Text>
      <Heading>Meet the team</Heading>
    </About>
      <Team />
  </Layout>
)

export default IndexPage
