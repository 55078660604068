import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Colors from "../variables/colors"

import Twitter from "../svgs/twitter.svg"
import Insta from "../svgs/instagram.svg"
import Github from "../svgs/github.svg"
import Glitch from "../svgs/glitch.svg"
import Linkedin from "../svgs/linkedin.svg"
import Medium from "../svgs/medium.svg"
import Devto from "../svgs/dev_to.svg"

const socialIcons = { Twitter, Insta, Github, Glitch, Linkedin, Medium, Devto };

const Employee = styled.li`
    width: 250px;
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    list-style: none;
    border: 10px solid ${Colors.orange};
    background-color: ${Colors.orange_dk};
    color: white;
    box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.3);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    & img {
        margin: 0;
        border: 2px solid ${Colors.charcoal};
    }
    &:first-child {
        transform: rotate(-2deg);
    }
    &:nth-child(2) {
        transform: rotate(1deg);
    }
    &:nth-child(3) {
        transform: rotate(2deg);
    }
    &:nth-child(4) {
        transform: rotate(-1deg);
    }
    &:nth-child(5) {
        transform: rotate(1deg);
    }
    &:hover {
        transform: rotate(0);
    }
`

const Name = styled.h3`
    margin: 0 0 10px 0;
    font-size: 1.2em;
    font-weight: normal;
`

const Role = styled.h4`
    min-height: 2em;
    margin: 10px 0 0;
    font-size: 1em;
`

const Stats = styled.ul`
    margin: 0;
    padding: 0;
    border-top: 1px solid white;
`

const Stat = styled.li`
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 2px;
    border-bottom: 1px solid white;
    font-size: 1.1em;
    list-style: none;
`
const Key = styled.span`
    font-size: 0.8em;
`

const Value = styled.span`
    font-size: 0.8em;
`

const Socials = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
`

const Social = styled.a`
    width: 32px;
    flex-grow: 0;
    & rect {
        fill: white;
    }
    & path, & circle {
        fill: ${Colors.orange_dk};
        stroke: ${Colors.orange_dk};
    }
    &:hover path {
        fill: ${Colors.orange};
        stroke: ${Colors.orange};
    }
`

export default function TopTrump(props) {
    let Statistics = [];
    let SocialLinks = [];

    for (const key in props.stats) {
        Statistics.push(<Stat key={key}><Key>{key}</Key><Value>{props.stats[key]}</Value></Stat>)
    }

    for (const key in props.socials) {
        const SocialIcon = socialIcons[key];
        const url = props.socials[key];
    
        SocialLinks.push(<Social key={key} href={url}><SocialIcon /></Social>)
    }

    return (
        <Employee>
            <Name>{props.name}</Name>
            <Img fluid={ props.img } alt={props.name} />
            <Role>{props.role}</Role>
           
            <Stats>
                {Statistics}
            </Stats>
            <Socials>
                {SocialLinks}
            </Socials>
        </Employee>
    );
}
