import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import TopTrump from "./toptrump"

const Employees = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 40px;
    padding: 0;
`

export default function Team() {

    const data = useStaticQuery(graphql`{
        allFile(filter: {relativeDirectory: {eq: "team"}, childImageSharp: {fluid: {}}}) {
        edges {
            node {
            base
            childImageSharp {
                fluid {
                    originalName
                    sizes
                    src
                    srcSet
                    aspectRatio
                }
            }
            }
        }
        }
    }`);

    const images = {};
    data.allFile.edges.forEach(edge => { images[edge.node.childImageSharp.fluid.originalName] = edge.node.childImageSharp.fluid; });

    return (
        <Employees>
            <TopTrump name="Ben Gamble" 
                      role="Head of Developer advocacy"
                      img={images["ben.jpg" ]}
                      stats={{
                          "Gaming": "100", 
                          "Talking Speed": "80", 
                          "Computers": "Over 9000!"
                      }}
                      socials={{
                          "Github": "https://github.com/Ugbot",
                          "Twitter": "https://twitter.com/BenGamble7",
                          "Linkedin": "https://www.linkedin.com/in/bengamble7/",
                      }}
            />
            <TopTrump name="Srushtika Neelakantam" 
                      role="Developer Advocate"
                      img={images["srushtika.jpg" ]}
                      stats={{
                          "WebVR": "100", 
                          "WebSockets": "100", 
                          "Public Speaking": "100"
                      }}
                      socials={{
                          "Github": "https://github.com/Srushtika/",
                          "Twitter": "https://twitter.com/Srushtika/",
                          "Linkedin": "https://www.linkedin.com/in/srushtika/",
                          "Glitch": "https://glitch.com/@SrushtikaNeelakantam",
                          "Medium": "https://medium.com/@n.srushtika",
                          "Devto": "https://dev.to/srushtika"
                      }}
            />
            <TopTrump name="Tom Camp" 
            role="Developer Advocate &amp; Realtime Engineer"
            img={images["tom.jpg" ]}
            stats={{
                "Ably Hub": "100", 
                "Existing": "-1", 
                "Rambling": "90"
            }}
            socials={{
                "Github": "https://github.com/tomczoink/",
                "Linkedin": "https://www.linkedin.com/in/thomascamp333/"
            }}
  />
            <TopTrump name="Jo Franchetti" 
                      role="Developer Advocate"
                      img={images["jo.jpg" ]}
                      stats={{
                          "CSS": "80", 
                          "Teaching": "80", 
                          "Glitter": "100"
                      }}
                      socials={{
                          "Github": "https://github.com/thisisjofrank",
                          "Twitter": "https://twitter.com/ThisIsJoFrank",
                          "Glitch": "https://glitch.com/@JoFranchetti",
                          "Medium": "https://medium.com/@jofranchetti"
                      }}
            />
                      <TopTrump name="Mark Cox-Smith" 
                      role="Customer Success Engineer"
                      img={images["mark.png" ]}
                      stats={{
                          "Customer Relations": "100", 
                          "Resistance to Ring": "2", 
                          "Speed": "80"
                      }}
                      socials={{
                          "Github": "https://github.com/markcoxsmith",
                          "Linkedin": "https://www.linkedin.com/in/mark-cox-smith-aa644a108/"
                      }}
            />
        </Employees>
    );
}
